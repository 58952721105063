import React from 'react';

interface Props {
  height?: React.SVGProps<SVGSVGElement>['height'];
  width?: React.SVGProps<SVGSVGElement>['width'];
}

export const BacklotLogo: React.FC<Props> = ({ height = 32, width = 32 }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8097 27.3813C24.9279 29.9474 20.8464 30.8965 16.0608 30.8965C10.9622 30.8965 6.784 30.1123 3.92935 27.5617C1.00469 24.9486 0 20.9786 0 16C0 10.9944 1.32144 7.0858 4.32528 4.50223C7.25488 1.98251 11.3587 1.10345 16.0608 1.10345C20.7617 1.10345 24.8434 1.98197 27.7463 4.51158C30.7179 7.10105 32 11.0105 32 16C32 20.8688 30.7482 24.7646 27.8097 27.3813ZM29.7931 16C29.7931 25.0238 25.1751 28.6821 16.0608 28.6821C6.0957 28.6821 2.2069 25.5115 2.2069 16C2.2069 6.73234 7.0679 3.31793 16.0608 3.31793C25.0536 3.31793 29.7931 6.73234 29.7931 16Z"
      fill="#10101C"
    />
    <path
      d="M8.5251 21.9674C9.74775 21.9674 10.3672 21.4783 10.3672 20.272C10.3672 19.033 9.73145 18.5766 8.5251 18.5766C7.31875 18.5766 6.66667 19.033 6.66667 20.272C6.66667 21.5435 7.18834 21.9674 8.5251 21.9674Z"
      fill="#10101C"
    />
    <path
      d="M19.0756 22C20.4775 22 21.7654 21.6088 22.7761 20.7937C23.7868 19.9623 24.39 18.7722 24.39 17.1094C24.39 13.7838 22.4338 12.0558 19.206 12.0558C17.2823 12.0558 15.7173 12.7079 14.8696 14.0609V9.33334H12.1635V21.7229H14.5762L14.7392 20.2068C15.4565 21.4131 16.8422 22 19.0756 22ZM18.1626 19.7666C15.8478 19.7666 14.7718 18.8863 14.7718 17.1094C14.7718 15.2673 16.0108 14.2565 18.1953 14.2565C20.5753 14.2565 21.5698 15.2347 21.5698 17.1094C21.5698 18.8374 20.6569 19.7666 18.1626 19.7666Z"
      fill="#10101C"
    />
  </svg>
);
