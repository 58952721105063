import { createMicroAppConfigContext } from '@amzn/sitc-frontend/contexts';
import { MicroAppConfigBaseResult } from '@amzn/sitc-frontend/types';

export const appDomainTemplate = 'studios-user-profile.app[.{stage}].frontend-platform.studios.a2z.com';

export type MicroAppConfigResultType = MicroAppConfigBaseResult;

export const MicroAppConfigContext = createMicroAppConfigContext<MicroAppConfigResultType>();

export const getStage = (): string => {
  const { hostname } = window.location;

  return hostname === 'localhost' ? 'dev' : hostname.match(/.*\b(dev|beta|gamma)\b.*/)?.[1] || '';
};
