import { UniversalHeaderConfig } from '@amzn/sitc-frontend/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { profileRoute } from '../configs/navigation';
import { BacklotLogo } from '../images/Backlot';
import { getStage } from '../utils/app';

const getStagePath = (path: string): string => {
  const stage = getStage();
  return stage === '' ? path.replace(/\[{stage}.\]/, '') : path.replace(/{stage}/, stage).replace(/[[\]]/g, '');
};

export const useUniversalHeaderConfig = (): UniversalHeaderConfig => {
  const { t } = useTranslation();
  return {
    productIdentifier: t('app.header.product_identifier'),
    logo: <BacklotLogo />,
    headerLinks: [
      {
        primary: t('app.header.your_profile'),
        path: profileRoute,
        reloadOnNav: false,
      },
    ],
    appSwitcherContent: [
      {
        navItems: [
          {
            iconKey: 'BacklotLogo',
            primary: 'universal_header.appswitcher.backlot.primary',
            secondary: 'universal_header.appswitcher.backlot.secondary',
          },
        ],
      },
      {
        subheader: 'BACKLOT APPS',
        navItems: [
          {
            iconKey: 'SourceLogo',
            primary: 'universal_header.appswitcher.source.primary',
            secondary: 'universal_header.appswitcher.source.secondary',
            path: getStagePath('https://[{stage}.]amazonbacklot.com/source'),
          },
          {
            iconKey: 'OriginLogo',
            primary: 'universal_header.appswitcher.origin_author.primary',
            secondary: 'universal_header.appswitcher.origin_author.secondary',
            path: getStagePath('https://[{stage}.]amazonbacklot.com/origin-author'),
          },
          {
            iconKey: 'OriginLogo',
            primary: 'universal_header.appswitcher.origin_view.primary',
            secondary: 'universal_header.appswitcher.origin_view.secondary',
            path: getStagePath('https://[{stage}.]amazonbacklot.com/origin-view'),
          },
          {
            iconKey: 'OriginLogo',
            primary: 'universal_header.appswitcher.origin_edit.primary',
            secondary: 'universal_header.appswitcher.origin_edit.secondary',
            path: getStagePath('https://[{stage}.]amazonbacklot.com/origin-edit'),
          },
          {
            iconKey: 'ReleasesLogo',
            primary: 'universal_header.appswitcher.releases.primary',
            secondary: 'universal_header.appswitcher.releases.secondary',
            path: getStagePath('https://[{stage}.]amazonbacklot.com/releases'),
          },
          {
            iconKey: 'SourceLogo',
            primary: 'universal_header.appswitcher.source_order.primary',
            secondary: 'universal_header.appswitcher.source_order.secondary',
          },
          {
            iconKey: 'ReleasesLogo',
            primary: 'universal_header.appswitcher.almanac.primary',
            secondary: 'universal_header.appswitcher.almanac.secondary',
            path: 'https://almanac.amazonstudios.com/',
          },
          {
            iconKey: 'StudiosAppsLogo',
            secondary: 'universal_header.appswitcher.studios_apps.secondary',
            path: 'https://releases.studiosapps.com/',
          },
          {
            iconKey: 'MgmLogo',
            secondary: 'universal_header.appswitcher.mgm.secondary',
            path: 'https://roar.mgm.com/login',
          },
        ],
      },
      {
        subheader: 'THIRD PARTY APPS',
        navItems: [
          {
            iconKey: 'RightslineLogo',
            secondary: 'universal_header.appswitcher.rightsline.secondary',
            hoverIconKey: 'OpenInNewIcon',
            path: 'https://app.rightsline.com/sso/login',
            newTab: true,
          },
          {
            iconKey: 'IconikLogo',
            secondary: 'universal_header.appswitcher.iconik.secondary',
            hoverIconKey: 'OpenInNewIcon',
            newTab: true,
          },
          {
            iconKey: 'FabricLogo',
            secondary: 'universal_header.appswitcher.fabric.secondary',
            hoverIconKey: 'OpenInNewIcon',
            newTab: true,
          },
          {
            iconKey: 'AirtableLogo',
            secondary: 'universal_header.appswitcher.airtable.secondary',
            hoverIconKey: 'OpenInNewIcon',
            path: 'https://airtable.com/sso/login',
            newTab: true,
          },
        ],
      },
    ],
  };
};
