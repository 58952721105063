import { StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import { ErrorBoundary } from './components/common/ErrorBoundary';
import StudiosUserProfileApp from './components/StudiosUserProfileApp';

const configuration = {};
const rootComponent = process.env.STANDALONE
  ? () => <StandaloneConfiguration RootComponent={StudiosUserProfileApp} configuration={configuration} />
  : StudiosUserProfileApp;

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  errorBoundary: (error: Error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
