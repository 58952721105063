export const en = {
  'app.header.product_identifier': 'backlot',
  'app.loading': 'Loading User Profile',
  'app.side_navigation.your_profile': 'Your Profile',
  'app.side_navigation.localization': 'Localization Settings',
  'app.side_navigation.theme': 'Theme Settings',
  'app.header.your_profile': 'Your Profile',
  'app.your_profile.localization.header': 'Localize your experience',
  'app.your_profile.localization.description': 'Keep your location and time zone up to date for a more personalized Backlot experience.',
  'app.your_profile.theme.header': 'Set your preferred theme',
  'app.your_profile.theme.description': 'Choose between light or dark mode to configure the Backlot product suite to suit your tastes.',
  'app.header.localization': 'Localization Settings',
  'app.button.save_localization': 'Save Localization Settings',
  'app.localization.location.header': 'Country / Region',
  'app.localization.location.desc': 'We collect this information to ensure we adhere to data privacy laws for your country.',
  'app.localization.location.placeholder': 'Location',
  'app.localization.location.set_automatically': 'Set location automatically',
  'app.localization.timezone.header': 'Time Zone',
  'app.localization.timezone.desc': 'We use this information to localize data or preconfigure certain apsects of the user interface.',
  'app.localization.timezone.placeholder': 'Time Zone',
  'app.localization.timezone.set_automatically': 'Set time zone automatically',
  'app.header.theme': 'Theme Settings',
  'app.button.save_theme': 'Save Theme Settings',
  'app.theme.header': 'Appearance',
  'app.theme.desc': 'This sets the default color scheme for all applications in the Backlot product suite.',
  'app.theme.tip': 'Pro Tip',
  'app.theme.tip.desc': 'Sync with your OS to automatically change modes when it does.',
  'app.theme.light': 'Light',
  'app.theme.dark': 'Dark',
  'app.theme.os_setting': 'Use OS Setting',
  'app.button.cancel': 'Cancel',
  'app.alert.localization.success': 'Your localization settings were saved.',
  'app.alert.localization.failure': 'Your localization settings could not be saved. Please try again.',
  'app.alert.theme.success': 'Your theme settings were saved.',
  'app.alert.theme.failure': 'Your theme settings could not be saved. Please try again.',
  'app.cancel_confirmation.header': 'Leave this page?',
  'app.cancel_confirmation.desc': 'Changes you made so far will be lost.',
  'app.cancel_confirmation.continue': 'Continue Editing',
  'app.cancel_confirmation.leave': 'Leave Page',
}
