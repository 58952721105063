import '../i18n';

import { AlertContextProvider, AppAlertBannerContextProvider, AuthSessionProvider, CommonUserPreferencesProvider, MicroAppConfigProvider } from '@amzn/sitc-frontend/contexts';
import { useUniversalHeader } from '@amzn/sitc-frontend/hooks';
import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { Radio } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import i18n from 'i18next';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { localizationRoute, profileRoute, themeRoute } from '../configs/navigation';
import { useUniversalHeaderConfig } from '../hooks/universal-header';
import { appDomainTemplate, MicroAppConfigContext, MicroAppConfigResultType } from '../utils/app';
import { AppLoading } from './common/AppLoading';
import { FeatureConfiguration } from './FeatureConfiguration';

const Index = React.lazy(() => import('../pages/Index'));
const Profile = React.lazy(() => import('../pages/Profile'));
const Localization = React.lazy(() => import('../pages/Localization'));
const Theme = React.lazy(() => import('../pages/Theme'));

const StudiosUserProfileApp: React.FC<MicroAppInitializationProps> = (props) => {
  const { authSession, basePath, stage, appId, featureOverrides, microAppName, radio, userPreferences, theme = backlotLightTheme } = props;
  useUniversalHeader({ radio: radio as unknown as Radio, microAppName, universalHeaderConfig: useUniversalHeaderConfig() });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MicroAppConfigProvider<MicroAppConfigResultType>
        stage={stage}
        appName={microAppName}
        appId={appId}
        appDomainTemplate={appDomainTemplate}
        appLoader={<AppLoading />}
        context={MicroAppConfigContext}>
        <FeatureConfiguration featureOverrides={featureOverrides}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter basename={basePath}>
              <AlertContextProvider closeOnLocationChange={false}>
                <AuthSessionProvider authSession={authSession}>
                  <CommonUserPreferencesProvider userPreferences={userPreferences} radio={radio}>
                    <div>
                      <Suspense fallback={<AppLoading />}>
                        <Routes>
                          <Route path="/" element={<Index />} />
                          <Route path={`/${profileRoute}`} element={<Profile />} />
                          <Route path={`/${localizationRoute}`} element={<Localization />} />
                          <Route path={`/${themeRoute}`} element={<Theme />} />
                        </Routes>
                      </Suspense>
                    </div>
                  </CommonUserPreferencesProvider>
                </AuthSessionProvider>
              </AlertContextProvider>
            </BrowserRouter>
          </I18nextProvider>
        </FeatureConfiguration>
      </MicroAppConfigProvider>
    </ThemeProvider>
  );
};

export default StudiosUserProfileApp;
